/* global $, clickedInsideOf */

class DesignSystemMultipleSelect {
  constructor (ms) {
    this.ms = ms
  }

  init () {
    this.bindEventsToMultipleSelect()
  }

  bindEventsToMultipleSelect () {
    const base = {}
    const $ms = $('#' + this.ms.prop('id')) // Select scoped multiple select from unique ID

    // Scoped selectors
    const $input = $ms.find('.js-ms-input')
    const $dropdown = $ms.find('.js-ms-dropdown')
    const $dropdownControl = $ms.find('.js-ms-dropdown-control')
    const $search = $ms.find('.js-ms-search')
    const $checkboxWrappers = $ms.find('.js-checkbox-wrapper')
    const $checkboxes = $ms.find('.js-checkbox-input')
    const $options = $ms.find('.js-ms-options')
    const $selectedOptions = $ms.find('.js-ms-selected-options')
    const $actions = $ms.find('.js-ms-actions')
    const $tags = $ms.find('.js-ms-tag')
    const $resultCount = $ms.find('.js-ms-results-count')
    const $groups = $ms.find('.js-md-grouped-options')

    base.setResultCount = function (number) {
      if (number === 1) {
        $resultCount.html(number + ' result')
      } else {
        $resultCount.html(number + ' results')
      }
    }

    base.focus = function () {
      base.handleTransition('focused')
    }

    base.unfocus = function () {
      base.handleTransition('unfocused')
    }

    base.handleTransition = function (direction) {
      switch (direction) {
        case 'focused':
          if (!$ms.hasClass('is-focused')) {
            $ms.addClass('is-focused')
            $dropdown.addClass('is-open')
            if (!$search.is(':focus')) {
              $search.focus()
            }
          }
          break

        case 'unfocused':
          if ($ms.hasClass('is-focused')) {
            $ms.removeClass('is-focused')
            $dropdown.removeClass('is-open')
            $search.blur()
            if (base.onDone) { base.onDone($ms) }
          }
          break

        default:
          throw new Error('Unsupported transition for multiple select:' + direction)
      }
    }

    base.showOrHideGroupLabels = function () {
      $groups.each(function () {
        const group = $(this).data('group')
        const checkboxes = $ms.find('.js-checkbox-input.is-visible[data-group="' + group + '"]')

        if (checkboxes.length) {
          $(this).removeClass('is-hidden')
        } else {
          $(this).addClass('is-hidden')
        }
      })
    }

    base.queryMatchesTerm = function (query, term) {
      return new RegExp(query).test(term)
    }

    base.showCheckboxesThatMatchQuery = function (query) {
      if (!query) {
        base.changeAllCheckboxes('show')
        $checkboxes.removeClass('is-focused')
      } else {
        $checkboxWrappers.each(function () {
          const term = $(this).data('term').toLowerCase()

          if (base.queryMatchesTerm(query, term)) {
            $(this).removeClass('is-hidden')
            $(this).find('.js-checkbox-input').addClass('is-visible')
          }
        })
      }

      // Set the visible checkboxes for global manipulation.
      base.visibleCheckboxes = $dropdown.find('.js-checkbox-input.is-visible')
      base.numberOfResults = base.visibleCheckboxes.length
      base.firstCheckbox = base.visibleCheckboxes.first()
      base.showOrHideGroupLabels()
      base.setResultCount(base.numberOfResults)

      if (query && base.firstCheckbox.length) {
        base.firstCheckbox.addClass('is-focused')
      }
    }

    base.findTag = function (value) {
      return $ms.find(".js-ms-tag[data-value='" + value + "']")
    }

    base.findCheckbox = function (value) {
      return $ms.find(".js-checkbox-input[value='" + value + "']")
    }

    base.handleSearch = function (event, query) {
      const escape = (event.keyCode === 27)
      const backspace = (event.keyCode === 8)
      const enter = (event.keyCode === 13)

      // Selectors
      const $lastSelectedTag = $selectedOptions.find('.js-ms-tag').last()

      $checkboxes.removeClass('is-focused')
      base.changeAllCheckboxes('hide')
      base.showCheckboxesThatMatchQuery(query)

      if (escape) {
        base.handleTransition('unfocused')
      }

      if (backspace) {
        if (base.keydownSearchLength === 0) {
          if ($lastSelectedTag.length) {
            base.transitionCheckbox(base.findCheckbox($lastSelectedTag.data('value')), false)
          }
        }
      }

      if (enter) {
        if (base.keyupSearchLength > 0) {
          base.transitionCheckbox(base.firstCheckbox, !base.firstCheckbox.prop('checked'))
          query = $search.val('')
          base.showCheckboxesThatMatchQuery(query)
          $checkboxes.removeClass('is-focused')
        }
      }
    }

    base.preventDefaultOnCertainKeys = function (event) {
      const enter = (event.keyCode === 13)
      const escape = (event.keyCode === 27)

      // This function does two things:
      // 1) Prevents escaping modals when escaping the multi-select
      // 2) Prevents submitting the form when checking checkboxes with enter

      if (enter || escape) {
        event.preventDefault()
        event.stopPropagation()
        return false
      }
    }

    base.transitionCheckboxByValue = function (value, checked) {
      const checkbox = base.findCheckbox(value)
      base.transitionCheckbox(checkbox, checked)
    }

    base.transitionCheckbox = function (checkbox, state) {
      const value = checkbox.val()
      const checked = checkbox.prop('checked')
      const stateDefined = typeof state !== 'undefined'
      const $tag = base.findTag(value)

      if (stateDefined) {
        if (state) {
          $search.before($tag)
          checkbox.prop('checked', true)
        } else {
          $options.append($tag)
          checkbox.prop('checked', false)
        }
      } else {
        if (checked) {
          $search.before($tag)
          // Clears the search input value when selecting a team member
          const query = $search.val('')
          base.showCheckboxesThatMatchQuery(query) // shows all team members by default
          $checkboxes.removeClass('is-focused')
        } else {
          $options.append($tag)
        }
      }

      $ms.trigger('choice')
    }

    base.changeAllCheckboxes = function (visibility) {
      switch (visibility) {
        case 'hide':
          $checkboxWrappers.addClass('is-hidden')
          $checkboxes.removeClass('is-visible')
          break

        case 'show':
          $checkboxWrappers.removeClass('is-hidden')
          $checkboxes.addClass('is-visible')
          break

        default:
          throw new Error('Unsupported visibility state for checkboxes:' + visibility)
      }
    }

    base.controlAllCheckboxes = function (action) {
      switch (action) {
        case 'select-all':
          base.visibleCheckboxes.each(function () { base.transitionCheckbox($(this), true) })
          break

        case 'deselect-all':
          base.visibleCheckboxes.each(function () { base.transitionCheckbox($(this), false) })
          break

        case 'done':
          base.handleTransition('unfocused')
          break

        default:
          throw new Error('Unsupported action for multiple select:' + action)
      }
    }

    base.selectedValues = function () {
      return $.makeArray(
        $ms.find('.js-checkbox-input:checked').map(function (i, el) {
          return $(el).prop('value')
        })
      )
    }

    base.setDoneCallback = function (cb) {
      base.onDone = cb
    }

    const handleClickOutside = function (event) {
      if (!clickedInsideOf(event.target, $ms)) {
        base.handleTransition('unfocused')
      }
    }

    base.destroy = function () {
      $(document).unbind('click', handleClickOutside)
    }

    base.init = function () {
      // Show all tags that are preselected
      $checkboxes.each(function () { base.transitionCheckbox($(this)) })

      $input.on('click', function () {
        base.handleTransition('focused')
      })

      $dropdownControl.on('click', function (e) {
        e.stopPropagation()
        if ($ms.hasClass('is-focused')) {
          base.handleTransition('unfocused')
        } else {
          base.handleTransition('focused')
        }
      })

      $search.on('focus', function () {
        base.handleTransition('focused')
      })

      $search.on('keydown', function (event) {
        base.keydownSearchLength = $(this).val().length
        base.preventDefaultOnCertainKeys(event)
      })

      $search.on('keyup', function (event) {
        base.keyupSearchLength = $(this).val().length
        base.handleSearch(event, $(this).val().toLowerCase())
        base.preventDefaultOnCertainKeys(event)
      })

      $search.on('blur', function () {
        if (base.firstCheckbox.length) {
          base.firstCheckbox.removeClass('is-focused')
        }
      })

      $checkboxes.on('change', function () {
        base.transitionCheckbox($(this))
      })

      $actions.on('click', function () {
        base.controlAllCheckboxes($(this).data('action'))
      })

      $(document).bind('click', handleClickOutside)

      $tags.on('click', function () {
        base.transitionCheckbox(base.findCheckbox($(this).data('value')), false)
      })
    }

    base.visibleCheckboxes = $dropdown.find('.js-checkbox-input.is-visible')
    base.numberOfResults = base.visibleCheckboxes.length
    base.setResultCount(base.numberOfResults)
    base.firstCheckbox = ''
    $ms[0].actions = {
      focus: base.focus,
      unfocus: base.unfocus,
      selectedValues: base.selectedValues,
      transitionCheckboxByValue: base.transitionCheckboxByValue,
      setDoneCallback: base.setDoneCallback,
      destroy: base.destroy
    }
    base.init()
  }
}

$(document).on('ready ajaxComplete', () => {
  $('.js-ms').each((index, target) => {
    const $ms = $(target)

    if (!$ms.data('initialized')) {
      new DesignSystemMultipleSelect($ms)
      new DesignSystemMultipleSelect($ms).init()
      $ms.data('initialized', true)
    }
  })
})
